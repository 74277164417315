const TRANSACTION_TYPES = {
  order: {
    filterName: {
      uk: '💳 Прямі донати',
      en: '💳 Replenishment',
    },
    tableName: {
      uk: '💳 Прямі донати',
      en: '💳 Replenishment',
    },
  },
  happy: {
    filterName: {
      uk: '🎂 Святкові Коло',
      en: '🎂 Happy KOLO',
    },
    tableName: {
      uk: '🎂',
      en: '🎂',
    },
  },
  additional: {
    filterName: {
      uk: '🛟 Допоміжні збори',
      en: '🛟 Additional',
    },
    tableName: {
      uk: '🛟',
      en: '🛟',
    },
  },
  mono: {
    filterName: {
      uk: '🐾 Mono-банка',
      en: '🐾 Mono-банка',
    },
    tableName: {
      uk: '🐾 Mono-банка',
      en: '🐾 Mono-банка',
    },
  },
};

const CURRENCY = {
  uah: '₴',
  usd: '$',
  cad: '$',
  eur: '€',
};

export { TRANSACTION_TYPES, CURRENCY };
