import { togglePasswordVisibility, validatePasswordInput } from './passwordField';
import signupFormValidation from './signupFormValidation';
import userProfileHeaderToggle from './userProfileHeaderToggle';
import updatePassword from './profileSettings';
import showMoreSubscriptions from './showMoreSubscriptions';
import saveChangesButtonState from './saveChangesButtonState';
import messageGenerator from './messageGenerator';

(() => {
  const passwordInputs = document.querySelectorAll('[type="password"]');

  userProfileHeaderToggle();
  signupFormValidation();
  togglePasswordVisibility();
  updatePassword();
  showMoreSubscriptions();
  saveChangesButtonState();
  messageGenerator();

  /**
   * Password fields validation on change field focus
   */
  if (passwordInputs.length) {
    passwordInputs.forEach((input) => {
      input.addEventListener('blur', validatePasswordInput);
    });
  }
})();
